import { library, dom } from '@fortawesome/fontawesome-svg-core'

// Solid
import {
  faTrash, faCog, faTimes, faEye
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  // Solid
  faTrash, faCog, faTimes, faEye
)

dom.watch() // This will kick of the replacement of i tags and configure a MutationObserver
