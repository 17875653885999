// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Required for Turbo
import 'regenerator-runtime/runtime'

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import '../stylesheets/application'

Rails.start()
ActiveStorage.start()

require('@hotwired/turbo-rails')

function requireAll(r) { r.keys().forEach(r); }

requireAll(require.context('../modules/', true, /\.js$/))
