
import { Controller } from "stimulus"

export default class extends Controller {
  // This stim controller triggers the Analytics events when it
  // connects to the DOM. This should be more reliable than expecting
  // them to trigger inline from the turbo-frame loaded confirmation page
  connect() {
    if(this.element.dataset.referrer.length > 0) {
      dataLayer.push({
        'event': 'Submitted Leads Form',
        'referring_page': this.element.dataset.referrer
      })
    } else {
      dataLayer.push({
        'event': 'Submitted Leads Form',
        'referring_page': 'None'
      })
    }
  }
}
